import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ga-upload',
	templateUrl: './ga-upload.component.html',
	styleUrls: ['./ga-upload.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaUploadComponent),
			multi: true,
		},
	],
})
export class GaUploadComponent
	extends FormFieldBaseDirective
	implements OnInit, OnDestroy
{
	@Input() icon = '';
	@Input() iconType = '';
	@Input() iconSize = 22.5;
	@Input() multiple = true;
	@Input() maxFileSizeMB = 0;
	@Input() fileTypes: string[] = [];
	@Input() uploadedFilesURL: string[] = [];
	@Output() changeUploaded = new EventEmitter();
	subscriptions: Subscription[] = [];
	files: Array<File> = [];

	constructor(private i18n: TranslocoService) {
		super();
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.valSubject
				.pipe(tap((value) => (this.files = value || [])))
				.subscribe()
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subs) => subs.unsubscribe());
	}

	onSelect(event) {
		this.showError = false;
		if (!this.multiple) this.files = [];
		if (
			this.maxFileSizeMB &&
			event.addedFiles.some((el) => this.maxFileSizeMB * 1000000 < el.size)
		) {
			this.error =
				this.i18n.translate('meter-page.label.attachment-max-size') +
				this.maxFileSizeMB +
				'mb';
			this.showError = true;
			return;
		}
		this.files.push(...event.addedFiles);
		this.value = this.files;
		this.files.forEach((file) => {
			this.getSourceUrl(file);
		});
	}

	getSourceUrl(file) {
		setTimeout(() => {
			const imgTag: HTMLImageElement = document.getElementById(
				file.name
			) as HTMLImageElement;
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				if (imgTag) imgTag.src = reader.result as string;
			};
		}, 0);
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
		this.value = this.files;
	}

	onRemoveAttached(event) {
		this.uploadedFilesURL.splice(this.uploadedFilesURL.indexOf(event), 1);
		this.changeUploaded.emit(this.uploadedFilesURL);
	}

	get acceptFileTypes() {
		if (!this.fileTypes.length) return '*';

		return this.fileTypes.join(',');
	}

	getNameByURL(url: string): string {
		let urlParts: string[] = [];
		let name = '';

		if (url.indexOf('/')) urlParts = url.split('/');

		name = urlParts.length ? urlParts[urlParts.length - 1] : url;
		name = name.indexOf('?') ? name.split('?')[0] : name;
		name = name.replace(/%20/g, ' ');
		return name;
	}

	get selectedFileTypes() {
		if (!this.fileTypes.length)
			return this.i18n.translate('file-upload-fields.all-file-types');

		if (this.fileTypes.length <= 3) {
			let text = '';
			for (let i = 0; i < this.fileTypes.length; i++)
				switch (i) {
				case 0:
					text = this.fileTypes[0].replace('.', '').toUpperCase();
					break;
				case this.fileTypes.length - 1:
					text = `${text} ${this.i18n.translate(
						'file-upload-fields.or'
					)} ${this.fileTypes[i].replace('.', '').toUpperCase()}`;
					break;
				default:
					text = `${text}, ${this.fileTypes[i]
						.replace('.', '')
						.toUpperCase()}`;
					break;
				}
			return text;
		}

		switch (true) {
		case this.fileTypes.some((type) => type.includes('pdf')) &&
				this.fileTypes.some((type) => type.includes('jpg')):
			return this.i18n.translate('file-upload-fields.images-and-documents');
		case this.fileTypes.some((type) => type.includes('pdf')):
			return this.i18n.translate('file-upload-fields.documents-only');
		case this.fileTypes.some((type) => type.includes('jpg')):
			return this.i18n.translate('file-upload-fields.images-only');
		default:
			return '';
		}
	}
}
