/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { SelectorGroup, SelectorOption } from '../models/selector.model';
import { Subject } from 'rxjs';

@Directive()
export abstract class FormFieldBaseDirective implements ControlValueAccessor {
	id!: number;
	@Input() val: any;
	@Input() title!: string;
	@Input() placeholder!: string;
	@Input() disabled = false;
	@Input() options: Array<SelectorOption> = [];
	@Input() groups: Array<SelectorGroup> = [];
	@Input() isMultiple = false;
	@Input() required = false;
	@Input() showError: boolean | undefined = false;
	@Input() error = '';
	@Input() tooltip = '';
	@Output() enterPress = new EventEmitter();
	valSubject: Subject<any> = new Subject<any>();

	constructor() {
		this.createRandomId();
	}

	onChange: any = () => {};
	onTouch: any = () => {};

	set value(val: any) {
		if (val !== undefined && this.val !== val) {
			this.val = val;
			this.onChange(val);
			this.onTouch(val);
			this.valSubject.next(val);
		}
	}
	// this method sets the value programmatically
	writeValue(value: any) {
		this.value = value;
	}
	// upon UI element value changes, this method gets triggered
	registerOnChange(fn: any) {
		this.onChange = fn;
	}
	// upon touching the element, this method gets triggered
	registerOnTouched(fn: any) {
		this.onTouch = fn;
	}

	createRandomId(): void {
		this.id = Math.floor(Math.random() * 10000);
	}

	tryEmitEnter(event) {
		if (event.keyCode === 13) this.enterPress.emit(true);
	}
}
