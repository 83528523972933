<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{
				"certification.list.title" | transloco
			}}</span>
			<span class="description__texts__subtitle">{{
				"certification.list.sub-title" | transloco
			}}</span>
		</div>
		<div class="btn-toolbar description__action-buttons" role="toolbar">
			<button
				class="btn btn-color-ga"
				*wchHasFeature="'certificateCreate'"
				type="button"
				(click)="doAction({ type: 'new' })"
			>
				<i class="fa-regular fa-store"></i>
				{{ "certification.details.new-certificate" | transloco }}
			</button>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs
			[idSelected]="organizationId"
			(orgEmitter)="orgSelected($event)"
		></autocomplete-orgs>
	</div>
	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ "certification.list.no-organization" | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'Certificates'"
			[element$]="displayEl$"
			(onAction)="doAction($event)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ "certification.list.no-content" | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 *ngIf="isOrgAdmin" class="empty-table__tips">
					<span>
						{{ "certification.list.no-content-sub1" | transloco }}
						<a (click)="doAction({ type: 'new' })">
							{{ "certification.list.no-content-sub2" | transloco }}
						</a>
					</span>
					<span>
						{{ "certification.list.no-content-sub3" | transloco }}
					</span>
				</h4>
				<h4 *ngIf="!isOrgAdmin" class="empty-table__tips">
					<span>
						{{ "certification.list.no-content-sub4" | transloco }}
					</span>
					<span>
						{{ "certification.list.no-content-sub5" | transloco }}
					</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
