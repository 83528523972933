import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	forwardRef,
} from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Auxiliar } from '../services/auxiliar';
import { GaDateRangePickerService } from './service/ga-date-range-picker.service';
import { DateView } from './models/date-option.model';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

class CustomDateAdapter extends MomentDateAdapter {
	getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
		return ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
	}

	format(date: Moment, displayFormat: string): string {
		const month = date.format('MMMM');
		const year = date.format('YYYY');

		return `${month} ${year}`;
	}
}

@Component({
	selector: 'ga-date-range-picker',
	templateUrl: './ga-date-range-picker.component.html',
	styleUrls: ['./ga-date-range-picker.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{
			provide: DateAdapter,
			useClass: CustomDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaDateRangePickerComponent),
			multi: true,
		},
	],
})
export class GaDateRangePickerComponent
	extends FormFieldBaseDirective
	implements OnInit, OnChanges
{
	@Input() inspectionDay: number | undefined;
	@Input() showTime = false;
	@Input() showOptions = true;
	@Input() initialPeriod = this.datePickerService.dateOptions[0].value;
	@Input() currentPeriod = new FormControl(this.initialPeriod);
	@Input() formattedDate = new FormControl('');
	@Input() position = 'left';
	@Input() startWithCurrentDate = true;
	@Input() hideClearButton = false;

	isOpen = false;
	dateOptions = this.datePickerService.dateOptions;

	selectedDate!: Moment | undefined;
	selectedWeek: DateRange<Moment> | undefined;
	selectedMonth: Moment = moment();
	selectedYear: Moment = moment();
	selectedInvoice: Moment = moment();
	selectedCustom: DateRange<Moment> | undefined;

	secondCalendarStart = new Date();

	showSpinners = true;
	showSeconds = false;
	stepHour = 1;
	stepMinute = 1;

	timeControlOne!: Date;
	timeControlTwo!: Date;

	constructor(
		private cdr: ChangeDetectorRef,
		private auxiliar: Auxiliar,
		private datePickerService: GaDateRangePickerService,
		private renderer: Renderer2,
		private el: ElementRef
	) {
		super();
	}

	ngOnInit(): void {
		this.secondCalendarStart.setMonth(new Date().getMonth() + 1);
		this.setInitialDate();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['initialPeriod']) {
			this.currentPeriod.setValue(changes['initialPeriod'].currentValue);
		}
	}

	setInitialDate() {
		if (!this.startWithCurrentDate) return;

		this.formattedDate.setValue(
			this.datePickerService[
				this.showTime ? 'formatDateWithTime' : 'formatDate'
			](new Date(this.selectedMonth.format()), this.currentPeriod.value)
		);
	}

	customHeader() {
		setTimeout(() => {
			const containers = this.el.nativeElement.querySelectorAll(
				'.mat-calendar-controls'
			);
			const periodButtons = this.el.nativeElement.querySelectorAll(
				'.mat-calendar-period-button'
			);
			const rightArrows = this.el.nativeElement.querySelectorAll(
				'.mat-calendar-next-button'
			);
			const leftSpaces = this.el.nativeElement.querySelectorAll(
				'.mat-calendar-spacer'
			);

			containers.forEach((container, index) => {
				const rightSpace = this.renderer.createElement('div');
				this.renderer.addClass(rightSpace, 'mat-calendar-spacer');

				this.renderer.insertBefore(
					container,
					periodButtons[index],
					rightArrows[index]
				);
				this.renderer.insertBefore(container, rightSpace, rightArrows[index]);
				this.renderer.insertBefore(
					container,
					leftSpaces[index],
					periodButtons[index]
				);
			});
		}, 0);
	}

	toggleCalendar() {
		if (this.disabled) return;

		this.isOpen = !this.isOpen;
		if (this.isOpen) this.customHeader();
	}

	clickedOutside() {
		this.isOpen = false;
	}

	changePeriod(period: DateView) {
		if (this.currentPeriod.value !== period) {
			this.currentPeriod.setValue(period);
			this.customHeader();
		}
	}

	setYear(normalizedYear: Moment, calendar: MatCalendar<Moment>) {
		if (!this.selectedYear.isValid()) {
			this.selectedYear = moment();
		}
		const ctrlValue = this.selectedYear;
		ctrlValue.date(1);
		ctrlValue.month(0);
		ctrlValue.year(normalizedYear.year());

		calendar.selected = ctrlValue;

		this.cdr.detach();
	}

	viewChangedHandler(event: any, calendar: MatCalendar<Moment>, currentView) {
		calendar.currentView = currentView;
		this.cdr.reattach();
	}

	setMonthAndYear(
		normalizedMonthAndYear: Moment,
		calendar: MatCalendar<Moment>,
		selectedDate: Moment
	) {
		if (!selectedDate.isValid()) {
			selectedDate = moment();

			if (this.currentPeriod.value === 'month') {
				this.selectedMonth = selectedDate;
			} else if (this.currentPeriod.value === 'invoice') {
				this.selectedInvoice = selectedDate;
			}
		}

		const ctrlValue = selectedDate;
		ctrlValue.date(1);
		ctrlValue.month(normalizedMonthAndYear.month());
		ctrlValue.year(normalizedMonthAndYear.year());

		calendar.selected = ctrlValue;
		this.cdr.detach();
	}

	selectedCustomChange(date: Moment) {
		if (
			this.selectedCustom?.start &&
			!this.selectedCustom?.end &&
			this.selectedCustom?.start < date
		) {
			this.selectedCustom = new DateRange<Moment>(
				this.selectedCustom?.start,
				date
			);
		} else {
			this.selectedCustom = new DateRange<Moment>(date, null);
		}
	}

	selectedWeekChange(startDate: Moment) {
		if (!this.selectedWeek?.start || this.selectedWeek?.end) {
			this.selectedWeek = new DateRange<Moment>(startDate, null);
			const start = this.selectedWeek.start;
			const end = moment(start).add(6, 'days');
			this.selectedWeek = new DateRange<Moment>(start, end);
		}
	}

	apply() {
		switch (this.currentPeriod.value) {
			case 'day':
				this.emitDay();
				break;
			case 'week':
				this.emitWeek();
				break;
			case 'month':
				this.emitMonthOrYear(this.selectedMonth, 'month');
				break;
			case 'year':
				this.emitMonthOrYear(this.selectedYear, 'year');
				break;
			case 'invoice':
				this.emitInvoiceDate();
				break;
			case 'custom':
				this.emitCustom();
				break;
		}

		this.toggleCalendar();
	}

	emitDay() {
		if (!this.selectedDate) {
			this.value = new DateRange<Date>(null, null);
			return;
		}

		const startDate = this.selectedDate.toDate();
		const endDate = this.selectedDate.toDate();
		endDate.setHours(23, 59, 59);

		if (this.showTime) {
			startDate.setHours(
				this.timeControlOne.getHours(),
				this.timeControlOne.getMinutes()
			);
			this.formattedDate.setValue(
				this.datePickerService.formatDateWithTime(
					startDate,
					this.currentPeriod.value
				)
			);
			this.value = startDate;
		} else {
			this.formattedDate.setValue(
				this.datePickerService.formatDate(startDate, this.currentPeriod.value)
			);
			this.value = new DateRange<Date>(startDate, endDate);
		}
	}

	emitWeek() {
		if (!this.selectedWeek) {
			this.value = new DateRange<Date>(null, null);
			return;
		}

		const startDate = this.selectedWeek!.start!.toDate();
		const endDate = this.selectedWeek!.end!.toDate();
		endDate.setHours(23, 59, 59);

		this.value = new DateRange<Date>(startDate, endDate);

		this.formattedDate.setValue(
			this.datePickerService.formatRange(
				startDate,
				endDate,
				this.currentPeriod.value
			)
		);
	}

	emitMonthOrYear(selected: Moment, period: any) {
		if (!selected.isValid()) {
			this.value = new DateRange<Date>(null, null);
			return;
		}

		const startDate = selected.toDate();
		startDate.setHours(0, 0, 0);

		const endDate = selected.endOf(period).toDate();
		endDate.setHours(23, 59, 59);

		this.value = new DateRange<Date>(startDate, endDate);

		this.formattedDate.setValue(
			this.datePickerService.formatDate(startDate, this.currentPeriod.value)
		);
	}

	emitCustom() {
		if (!this.selectedCustom) {
			this.value = new DateRange<Date>(null, null);
			return;
		}
		if (!this.selectedCustom.start || !this.selectedCustom.end) return;

		const startDate = this.selectedCustom.start.toDate();
		const endDate = this.selectedCustom.end.toDate();
		endDate.setHours(23, 59, 59);

		if (this.showTime) {
			startDate.setHours(
				this.timeControlOne.getHours(),
				this.timeControlOne.getMinutes()
			);
			endDate.setHours(
				this.timeControlTwo.getHours(),
				this.timeControlTwo.getMinutes()
			);

			this.formattedDate.setValue(
				this.datePickerService.formatRangeWithTime(
					startDate,
					endDate,
					this.currentPeriod.value
				)
			);
		} else {
			this.formattedDate.setValue(
				this.datePickerService.formatRange(
					startDate,
					endDate,
					this.currentPeriod.value
				)
			);
		}

		this.value = new DateRange<Date>(startDate, endDate);
	}

	emitInvoiceDate() {
		if (!this.selectedInvoice.isValid()) {
			this.value = new DateRange<Date>(null, null);
			return;
		}

		let startDate: Date = this.selectedInvoice.toDate();
		startDate.setHours(0, 0, 0);
		let endDate: Date | undefined;

		let inspectionDate: Date | undefined;

		if (this.inspectionDay != undefined) {
			let referenceDate: Date;
			if (startDate.getDate() > this.inspectionDay && this.inspectionDay != 0) {
				referenceDate = moment(startDate).add(1, 'M').millisecond(0).toDate();
			} else {
				referenceDate = startDate;
			}
			inspectionDate = moment(
				this.auxiliar.getInspectionDate(this.inspectionDay, referenceDate)
			)
				.hour(0)
				.millisecond(0)
				.toDate();
		}

		if (inspectionDate) {
			if (this.inspectionDay == 0) {
				startDate = moment(inspectionDate)
					.set({ date: 1, minute: 0, hour: 0, second: 0, millisecond: 0 })
					.toDate();
			} else {
				startDate = moment(inspectionDate)
					.subtract(1, 'months')
					.add(1, 'days')
					.millisecond(0)
					.toDate();
			}
		} else if (startDate == undefined) {
			startDate = moment().millisecond(0).toDate();
		}

		if (inspectionDate) {
			if (this.inspectionDay == 0) {
				endDate = moment(startDate)
					.add(1, 'months')
					.subtract(1, 'days')
					.set({ hour: 23, minute: 59, second: 59 })
					.toDate();
			} else {
				endDate = moment(inspectionDate)
					.millisecond(0)
					.set({ hour: 23, minute: 59, second: 59 })
					.toDate();
			}
		} else if (endDate == undefined) {
			endDate = moment(startDate)
				.add(1, 'months')
				.subtract(1, 'days')
				.millisecond(0)
				.set({ hour: 23, minute: 59, second: 59 })
				.toDate();
		}

		this.value = new DateRange<Date>(startDate, endDate);

		this.formattedDate.setValue(
			this.datePickerService.formatRange(
				startDate,
				endDate,
				this.currentPeriod.value
			)
		);
	}

	clear() {
		switch (this.currentPeriod.value) {
			case 'day':
				this.selectedDate = undefined;
				break;
			case 'week':
				this.selectedWeek = undefined;
				break;
			case 'month':
				this.selectedMonth = moment(null);
				break;

			case 'year':
				this.selectedYear = moment(null);
				break;

			case 'invoice':
				this.selectedInvoice = moment(null);
				break;

			case 'custom':
				this.selectedCustom = undefined;
				break;
		}
		this.formattedDate.setValue(
			this.datePickerService.formatRange(null, null, this.currentPeriod.value)
		);
		this.apply();
	}
}
