import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { StateParams, StateService } from 'angular-ui-router';
import { Subscription, from } from 'rxjs';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { CertificateListService } from '../certificate-list/certificate-list.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'certificate-details',
	templateUrl: './certificate-details.component.html',
	styleUrls: ['./certificate-details.component.scss'],
})
export class CertificateDetailsComponent implements OnInit, OnDestroy {
	certificate: any = {};
	organization: number | undefined;
	subscriptions: Subscription[] = [];
	certificateForm!: FormGroup;
	fileTypes = ['.pfx'];
	isSaving = false;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private certificateListService: CertificateListService,
		private notificationService: NotificationService,
		private i18n: TranslocoService
	) {
		this.certificate.id = this.$stateParams.id;
		this.organization = this.$stateParams.organizationId;
		this.initFormGroup();
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnInit(): void {
		this.loadCertificate();
	}

	initFormGroup() {
		const group: any = {
			organization: new FormControl('', Validators.required),
			certificatePassword: new FormControl(''),
			cceeUser: new FormControl(''),
			cceePassword: new FormControl(''),
			certificate: new FormControl(''),
			expirationReport: new FormControl(false),
		};
		this.certificateForm = new FormGroup(group);
	}

	loadCertificate() {
		const { id } = this.$stateParams;
		if (!id) return;

		const subscription = this.certificateListService
			.getCertificate(id)
			.pipe(
				tap((response: any) => {
					const { certificate } = response;
					this.certificate = certificate;

					Object.keys(this.certificateForm.controls).forEach((key) => {
						if (certificate[key])
							this.certificateForm.controls[key].setValue(certificate[key]);
					});
				}),
				finalize(() =>
					this.clearValidators([
						'cceeUser',
						'cceePassword',
						'certificatePassword',
						'certificate',
					])
				)
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	clearValidators(controls: Array<string>) {
		controls.forEach((control) => {
			this.certificateForm.controls[control].clearValidators();
			this.certificateForm.controls[control].updateValueAndValidity();
		});
	}

	orgSelected(event: { id: number; name: string }) {
		if (this.organization === event?.id) return;
		this.organization = event?.id;
		this.certificateForm.controls['organization'].setValue(this.organization);
		this.formDisable(!this.organization);
	}

	formDisable(value: boolean) {
		if (value) {
			this.certificateForm.disable();
		} else {
			this.certificateForm.enable();
		}
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'certificate', organizationId: this.organization },
			{
				inherit: false,
				reload: false,
				notify: false,
			}
		);
	}
	onSubmit() {
		this.isSaving = true;

		const formData = new FormData();
		Object.keys(this.certificateForm.value).forEach((key) => {
			if (key === 'certificate' && this.certificateForm.value[key]) {
				formData.append(
					'certificate',
					this.certificateForm.value[key][0],
					this.certificateForm.value[key][0].name
				);
			} else if (
				typeof this.certificateForm.value[key] == 'boolean' ||
				typeof this.certificateForm.value[key]
			) {
				formData.append(key, this.certificateForm.value[key]);
			}
		});

		const subscription = from(
			this.certificate.id
				? this.certificateListService.updateCertificate(
					this.certificate.id,
					formData
				  )
				: this.certificateListService.createCertificate(formData)
		)
			.pipe(
				tap(() => {
					this.notificationService.success({
						msg: this.i18n.translate('certification.details.save-success'),
					});
					this.backTo();
				}),
				catchError((e) => {
					this.notificationService.error({
						msg: this.i18n.translate(e.error.message),
					});
					return e;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}
}

export const ng2CertificateDetailsComponent = {
	name: 'certificateDetails',
	def: downgradeComponent({
		component: CertificateDetailsComponent,
		propagateDigest: true,
	}),
};
