import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TariffListService } from '../tariff-list/tariff-list.service';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Subscription, from } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { GaDateRangePickerService } from 'webapp/app/shared/ga-date-range-picker/service/ga-date-range-picker.service';
import { DashboardDistributor } from 'webapp/app/shared/models/distributor.model';
import { SelectorOption } from 'webapp/app/shared/models/selector.model';
import { CredentialsService } from 'webapp/app/shared/services/credentials.service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'tariff-details',
	templateUrl: './tariff-details.component.html',
	styleUrls: ['./tariff-details.component.scss'],
})
export class TariffDetailsComponent implements OnInit, OnDestroy {
	organization: number | undefined;
	subscriptions: Subscription[] = [];
	type: string | undefined = '';
	title: string | undefined = '';
	subtitle: string | undefined = '';
	isSaving = false;
	tariffForm!: FormGroup;
	dateRange = new FormControl();
	formattedDate = new FormControl();
	distributors: Array<DashboardDistributor> = [];
	distributorsOptions: Array<SelectorOption> = [];
	tariffTypeOptions: Array<SelectorOption> = [];
	subGroupsOptions: Array<SelectorOption> = [];
	firstCycle = false;
	disableTariffForm = false;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private datePipe: DatePipe,
		private i18n: TranslocoService,
		private notification: NotificationService,
		private credentialsService: CredentialsService,
		private tariffListService: TariffListService,
		private datePickerService: GaDateRangePickerService
	) {
		this.type = this.$stateParams['#'];
	}

	ngOnInit(): void {
		this.initFormGroup();
		this.loadDistributors();
		this.loadTariffTypes();
		this.loadSubGroups();
	}

	ngOnDestroy(): void {
		sessionStorage.removeItem('selectedRow');
		this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
	}

	setTitle() {
		switch (this.type) {
			case 'privateTariff':
			case 'publicTariff': {
				this.firstCycle = !this.$stateParams['instanceId'];
				this.title = 'regulated-list.new-tariff';
				this.subtitle = 'regulated-list.new-tariff-sub';
				break;
			}
			case 'view': {
				this.title = 'regulated-list.view-tariff';
				this.subtitle = 'regulated-list.view-tariff-sub';
				this.setPublicCycle(this.$stateParams['id']);
				this.disableTariffForm = true;
				this.formDisable(true);
				break;
			}
			case 'edit': {
				this.title = 'regulated-list.edit-tariff';
				this.subtitle = 'regulated-list.edit-tariff-sub';
				this.getTariff(this.$stateParams['id']);
				break;
			}
			case 'new-cycle': {
				this.title = 'regulated-list.new-cycle';
				this.subtitle = 'regulated-list.new-cycle-sub';
				this.disableTariffForm = true;
				this.getTariff(this.$stateParams['id']);
				break;
			}
			case 'edit-cycle': {
				this.title = 'regulated-list.edit-cycle';
				this.subtitle = 'regulated-list.edit-cycle-sub';
				this.disableTariffForm = true;
				this.getTariff(
					this.$stateParams['id'],
					this.$stateParams['instanceId']
				);
				break;
			}
			case 'clone': {
				this.title = 'regulated-list.new-tariff';
				this.subtitle = 'regulated-list.new-tariff-sub';
				this.disableTariffForm = true;
				this.getTariff(
					this.$stateParams['id'],
					this.$stateParams['instanceId']
				);
				break;
			}
			default:
				break;
		}
	}

	initFormGroup() {
		const group: any = {
			id: new FormControl(null),
			tariff: new FormControl(null),
			name: new FormControl(null),
			organization: new FormControl(null),
			company: new FormControl(null),
			private: new FormControl(this.type === 'privateTariff'),
			tariffType: new FormControl(null),
			distributorId: new FormControl(null),
			distributor: new FormControl(null),
			subGroup: new FormControl(null),
		};

		switch (this.type) {
			case 'privateTariff': {
				this.initDates(group);
				this.initCycleForm(group);
				break;
			}
			case 'publicTariff': {
				delete group.organization;
				delete group.company;
				this.initDates(group);
				this.initCycleForm(group);
				break;
			}
			case 'edit': {
				group.id = new FormControl(null);
				break;
			}
			default:
				group.id = new FormControl(null);
				this.initDates(group);
				this.initCycleForm(group);
				break;
		}
		this.tariffForm = new FormGroup(group);
		this.addIcmsItem();
	}

	formDisable(value: boolean) {
		if (value) {
			this.tariffForm.disable();
			this.dateRange.disable();
		} else {
			this.tariffForm.enable();
			this.dateRange.enable();
		}
	}

	initDates(ObjectForm) {
		ObjectForm.startDate = new FormControl(null, [Validators.required]);
		ObjectForm.endDate = new FormControl(null, [Validators.required]);
	}

	initCycleForm(ObjectForm) {
		ObjectForm.fields = new FormGroup({
			energyRate: new FormControl(null),
			tariffFlagSection: new FormControl(false),
			intermediatePeakEnergyRateSeason1: new FormControl(null),
			peakHourSection: new FormControl(true), // preencher com true se o tipo for media/alta verde
			startPeakTime: new FormControl(null),
			endPeakTime: new FormControl(null),
			peakEnergyRateSeason1: new FormControl(null),
			offPeakEnergyRateSeason1: new FormControl(null),
			peakTransmitionRateSeason1: new FormControl(null),
			offPeakTransmitionRateSeason1: new FormControl(null),
			demandRate: new FormControl(null),
			overDemandRate: new FormControl(null),
			toleranceRate: new FormControl(5),
			minPowerFactor: new FormControl(0.92),
			capacitiveStartHour: new FormControl(null),
			capacitiveEndHour: new FormControl(null),
			demandSection: new FormControl(true),
			peakDemandRate: new FormControl(null),
			offPeakDemandRate: new FormControl(null),
			overPeakDemandRate: new FormControl(null),
			overOffPeakDemandRate: new FormControl(null),
			startFirstIntermediatePeakTime: new FormControl(null),
			endFirstIntermediatePeakTime: new FormControl(null),
			startLastIntermediatePeakTime: new FormControl(null),
			endLastIntermediatePeakTime: new FormControl(null),
			discountSection: new FormControl(true),
			TUSDGenerationRate: new FormControl(null),
			TUSDDGenerationDiscount: new FormControl(null),
			taxesSection: new FormControl(true),
			pis: new FormControl(null),
			cofins: new FormControl(null),
			noIcmsOnPisCofinsBase: new FormControl(false),
			vat: new FormControl(null),
			icms: new FormArray([]),
		});
	}

	get icmsFormArray() {
		return this.tariffForm.get('fields.icms') as FormArray;
	}

	removeICMS(i: number) {
		if (this.icmsFormArray.length === 1) {
			const contact = this.icmsFormArray.at(i) as FormGroup;
			Object.keys(contact.controls).forEach((key) => {
				contact.controls[key].setValue(null);
			});
		} else {
			this.icmsFormArray.removeAt(i);
		}
	}

	addIcmsItem() {
		if (!this.icmsFormArray) return;
		const lastItem = this.icmsFormArray.at(
			this.icmsFormArray.length - 1
		) as FormGroup;
		const groupICMS = new FormGroup({
			to: new FormControl(
				lastItem && lastItem.get('from')?.value
					? lastItem.get('from')?.value
					: null
			),
			from: new FormControl(null),
			percent: new FormControl(null),
		});
		this.icmsFormArray.push(groupICMS);
	}

	loadDistributors() {
		const subscription = from(
			this.credentialsService.getDashboardDistributors()
		)
			.pipe(
				tap((distributors: DashboardDistributor[]) => {
					this.distributors = distributors;
					this.distributorsOptions = distributors.map((dtb) => ({
						id: dtb.name,
						label: dtb.name,
					}));
				}),
				finalize(() => this.setTitle())
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	loadTariffTypes() {
		const subscription = this.tariffListService
			.getTariffsTypes(null)
			.pipe(
				map((tariffTypes) => {
					if (this.type == 'publicTariff') {
						return tariffTypes.filter((tariff) =>
							[5, 8, 17].includes(tariff.id)
						);
					}
					return tariffTypes.filter(
						(tariff) => ![6, 7, 15].includes(tariff.id)
					);
				}),
				tap((tariffTypes) => {
					this.tariffTypeOptions = tariffTypes
						.map((tt) => ({
							id: tt.id,
							label: this.i18n.translate(
								`regulated-list.tariff-types.${tt.name.toLowerCase()}`
							),
						}))
						.sort((a, b) => {
							if (a.label > b.label) {
								return 1;
							}
							if (a.label < b.label) {
								return -1;
							}
							return 0;
						});
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	loadSubGroups() {
		const subscription = this.tariffListService
			.getSubgroups(null)
			.pipe(
				tap((response) => {
					this.subGroupsOptions = response.subGroups.map((sub) => ({
						id: sub.id,
						label: sub.name,
					}));
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	changeDistributor(event) {
		const dashboardDistributor = this.distributors.find(
			(d) => d.name.toLowerCase() === event.toLowerCase()
		);

		this.tariffForm.controls['distributorId'].patchValue(dashboardDistributor);
	}

	orgSelected(event: { id: number; name: string }) {
		this.organization = event?.id;
		this.tariffForm.controls['organization'].setValue(event?.id);
	}

	companySelected(event: { id: number; name: string }) {
		if (!event || !event.id) return;
		this.tariffForm.controls['company'].setValue(event?.id);
	}

	changeDate() {
		if (!this.dateRange.value) return;

		const startDate = this.dateRange.value.start
			? this.datePipe.transform(
					this.dateRange.value.start,
					'yyyy-MM-ddTHH:mm:ssZ'
			  )
			: null;
		this.tariffForm.controls['startDate'].setValue(startDate);

		const endDate = this.dateRange.value.end
			? this.datePipe.transform(
					this.dateRange.value.end,
					'yyyy-MM-ddTHH:mm:ssZ'
			  )
			: null;
		if (!startDate && !endDate) this.dateRange.setValue(null);
		this.tariffForm.controls['endDate'].setValue(endDate);
	}

	onTimeChanged(
		event: { start: string; end: string },
		fields: { start: string; end: string }
	) {
		this.tariffForm.get(fields.start)?.setValue(event.start);
		this.tariffForm.get(fields.end)?.setValue(event.end);
	}

	changeTariffType(event) {
		let formList: string[] = [];

		switch (event) {
			case 2: {
				formList = [
					'startPeakTime',
					'endPeakTime',
					'peakTransmitionRateSeason1',
					'peakEnergyRateSeason1',
					'offPeakTransmitionRateSeason1',
					'offPeakEnergyRateSeason1',
					'demandRate',
					'overDemandRate',
					'toleranceRate',
					'minPowerFactor',
					'capacitiveStartHour',
					'capacitiveEndHour',
					'cofins',
					'pis',
				];
				break;
			}
			case 3: {
				formList = [
					'startPeakTime',
					'endPeakTime',
					'peakTransmitionRateSeason1',
					'peakEnergyRateSeason1',
					'offPeakTransmitionRateSeason1',
					'offPeakEnergyRateSeason1',
					'peakDemandRate',
					'offPeakDemandRate',
					'overPeakDemandRate',
					'overOffPeakDemandRate',
					'toleranceRate',
					'minPowerFactor',
					'capacitiveStartHour',
					'capacitiveEndHour',
					'cofins',
					'pis',
				];
				break;
			}
			case 5: {
				formList = ['energyRate', 'pis', 'cofins'];
				break;
			}
			case 8: {
				formList = [
					'peakEnergyRateSeason1',
					'offPeakEnergyRateSeason1',
					'intermediatePeakEnergyRateSeason1',
					'startPeakTime',
					'endPeakTime',
					'startFirstIntermediatePeakTime',
					'endFirstIntermediatePeakTime',
					'startLastIntermediatePeakTime',
					'endLastIntermediatePeakTime',
					'pis',
					'cofins',
				];
				break;
			}
			case 16: {
				formList = [
					'TUSDDGenerationDiscount',
					'TUSDGenerationRate',
					'pis',
					'cofins',
				];
				break;
			}
			case 17: {
				formList = ['energyRate', 'vat'];
				break;
			}
			default:
				break;
		}
		if (this.tariffForm.get('fields'))
			Object.keys(this.tariffForm.get('fields')?.value).forEach((key) => {
				switch (true) {
					case formList.includes(key):
						this.tariffForm
							.get(`fields.${key}`)
							?.setValidators(Validators.required);
						break;
					default:
						if (
							key !== 'icms' &&
							typeof this.tariffForm.get(`fields.${key}`)?.value !== 'boolean'
						) {
							this.tariffForm.get(`fields.${key}`)?.patchValue(null);
							this.tariffForm.get(`fields.${key}`)?.clearValidators();
						}
						break;
				}

				this.tariffForm.get(`fields.${key}`)?.updateValueAndValidity();
			});
	}

	getTariff(id, instanceId = null) {
		const subscription = this.tariffListService
			.getTariffs({ id })
			.pipe(
				tap((response) => {
					Object.keys(this.tariffForm.value).forEach((key) => {
						this.tariffForm.controls[key].patchValue(response.tariff[key]);
						if (key === 'tariffType') {
							this.tariffForm.controls[key].patchValue(response.tariff[key].id);
						}
						this.tariffForm.controls[key].updateValueAndValidity();
					});
				}),
				switchMap(() => {
					if (!instanceId) return EMPTY;
					return this.tariffListService.getTariffInstances({ id, instanceId });
				}),
				tap((response) => {
					if (!response.tariffInstance) return;
					const fields = ['startDate', 'endDate', 'tariff', 'id', 'fields'];
					fields.forEach((field) => {
						switch (field) {
							case 'fields': {
								Object.keys(response.tariffInstance[field]).forEach((key) => {
									if (key === 'icms') {
										for (
											let i = 0;
											i < response.tariffInstance[field][key].length - 1;
											i++
										) {
											this.addIcmsItem();
										}
									}
									this.tariffForm
										.get('fields.' + key)
										?.patchValue(response.tariffInstance[field][key]);
									this.tariffForm
										.get('fields.' + key)
										?.updateValueAndValidity();
								});
								break;
							}
							default: {
								this.tariffForm.controls[field].patchValue(
									response.tariffInstance[field]
								);
								this.tariffForm.controls[field].updateValueAndValidity();
								break;
							}
						}
					});
					const startDate = new Date(this.tariffForm.value.startDate);
					const endDate = new Date(this.tariffForm.value.endDate);

					this.formattedDate.setValue(
						this.datePickerService.formatRange(startDate, endDate, 'custom')
					);
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	setPublicCycle(id) {
		const subscription = this.tariffListService
			.getTariffs({ id })
			.pipe(
				map((response) => {
					Object.keys(this.tariffForm.value).forEach((key) => {
						this.tariffForm.controls[key].patchValue(response.tariff[key]);
						if (key === 'tariffType') {
							this.tariffForm.controls[key].patchValue(response.tariff[key].id);
						}
						this.tariffForm.controls[key].updateValueAndValidity();
					});
					const tariffInstance = sessionStorage.getItem('selectedRow') || '';
					return JSON.parse(tariffInstance);
				}),
				tap((tariffInstance) => {
					if (!tariffInstance) return;
					const fields = ['startDate', 'endDate', 'tariff', 'fields'];
					fields.forEach((field) => {
						switch (field) {
							case 'fields': {
								Object.keys(tariffInstance[field]).forEach((key) => {
									if (key === 'icms') {
										for (
											let i = 0;
											i < tariffInstance[field][key].length - 1;
											i++
										) {
											this.addIcmsItem();
										}
									}
									this.tariffForm
										.get('fields.' + key)
										?.patchValue(tariffInstance[field][key]);
									this.tariffForm
										.get('fields.' + key)
										?.updateValueAndValidity();
								});
								break;
							}
							default: {
								this.tariffForm.controls[field].patchValue(
									tariffInstance[field]
								);
								this.tariffForm.controls[field].updateValueAndValidity();
								break;
							}
						}
					});
					const startDate = new Date(this.tariffForm.value.startDate);
					const endDate = new Date(this.tariffForm.value.endDate);

					this.formattedDate.setValue(
						this.datePickerService.formatRange(startDate, endDate, 'custom')
					);
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	backTo() {
		this.$state.transitionTo(
			'contracts',
			{ tab: 'tariffs', organizationId: this.organization },
			{
				inherit: false,
				reload: false,
				notify: false,
			}
		);
	}

	prepareTariffPayload() {
		const {
			company,
			organization,
			name,
			tariffType,
			subGroup,
			distributorId,
			id,
		} = this.tariffForm.value;

		return {
			companyId: company,
			organizationId: organization,
			private: this.tariffForm.controls['private'].value,
			id,
			name,
			tariffType,
			subGroup,
			distributorId,
		};
	}

	prepareInstancePayload() {
		if (!this.tariffForm.controls['fields']) return;

		Object.keys(this.tariffForm.controls['fields'].value).forEach((key) => {
			const field = this.tariffForm.controls['fields'].value[key];
			if (typeof field !== 'boolean' && !field) {
				delete this.tariffForm.controls['fields'].value[key];
			}
		});

		const rateSeason = [
			'peakEnergyRateSeason',
			'offPeakEnergyRateSeason',
			'peakTransmitionRateSeason',
			'offPeakTransmitionRateSeason',
		];
		rateSeason.forEach((key) => {
			if (
				this.tariffForm.controls['fields'].value[`${key}2`] &&
				!this.tariffForm.controls['fields'].value[`${key}1`]
			)
				this.tariffForm.controls['fields'].value[`${key}1`] =
					this.tariffForm.controls['fields'].value[`${key}2`];
		});
	}
	onSubmit() {
		this.prepareInstancePayload();
		const tariffPayload = this.prepareTariffPayload();

		this.isSaving = true;
		switch (this.type) {
			case 'privateTariff':
			case 'publicTariff': {
				delete tariffPayload.id;
				this.createTariff(tariffPayload);
				break;
			}
			case 'edit': {
				this.editTariff(tariffPayload);
				break;
			}
			case 'new-cycle': {
				this.saveCycle({
					tariff: this.tariffForm.controls['id'].value,
					startDate: this.tariffForm.controls['startDate'].value,
					endDate: this.tariffForm.controls['endDate'].value,
					fields: this.tariffForm.controls['fields'].value,
				});
				break;
			}
			case 'edit-cycle': {
				this.saveCycle({
					id: this.tariffForm.controls['id'].value,
					tariff: this.tariffForm.controls['tariff'].value,
					startDate: this.tariffForm.controls['startDate'].value,
					endDate: this.tariffForm.controls['endDate'].value,
					fields: this.tariffForm.controls['fields'].value,
				});
				break;
			}
			case 'clone': {
				this.saveCycle({
					tariff: this.tariffForm.controls['tariff'].value,
					startDate: this.tariffForm.controls['startDate'].value,
					endDate: this.tariffForm.controls['endDate'].value,
					fields: this.tariffForm.controls['fields'].value,
				});
				break;
			}
			default:
				break;
		}
	}

	createTariff(tariffPayload) {
		const subscription = this.tariffListService
			.createTariff(tariffPayload)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('regulated-list.save-success'),
					});
				}),
				tap((response) => {
					this.saveCycle({
						tariff: response.tariff.id,
						startDate: this.tariffForm.controls['startDate'].value,
						endDate: this.tariffForm.controls['endDate'].value,
						fields: this.tariffForm.controls['fields'].value,
					});
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return error;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	editTariff(tariffPayload) {
		const subscription = this.tariffListService
			.saveTariff(tariffPayload)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('regulated-list.save-success'),
					});
					this.backTo();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return error;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	saveCycle(bodyCicle, message = '') {
		const functionName = bodyCicle.id
			? 'saveTariffInstance'
			: 'createTariffInstance';
		if (bodyCicle.fields.icms && !bodyCicle.fields.icms.length) {
			delete bodyCicle.fields.icms;
		}
		const subscription = this.tariffListService[functionName](bodyCicle)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate(message || 'regulated-list.cycle-success'),
					});
					this.backTo();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return error;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}
}

export const ng2TariffDetailsComponent = {
	name: 'tariffDetails',
	def: downgradeComponent({
		component: TariffDetailsComponent,
		propagateDigest: true,
	}),
};
