<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar navbar-default">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">{{
						"main.menu.contracts-and-tariffs" | transloco
					}}</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()">{{ "contract.label.tariffs" | transloco }}</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span class="navbar-content__breadcrumb-page">{{
						title | transloco
					}}</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Title description of page ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{ title | transloco }}</span>
				<span class="description__texts__subtitle">{{
					subtitle | transloco
				}}</span>
			</div>
		</div>

		<!-- ------------------------- Form Module ------------------------- -->
		<form
			id="meterForm"
			[formGroup]="tariffForm"
			class="flex-col"
			(ngSubmit)="onSubmit()"
			focusInvalidInput
		>
			<!-- Basic Data -->
			<div id="basicData" class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "global.basic-info" | transloco }}
				</h3>
				<div class="row p-uns col-lg-12">
					<!-- Nome -->
					<div class="form-group col-lg-4 col-sm-12">
						<ga-input
							[title]="'regulated-list.form.name' | transloco"
							[placeholder]="'regulated-list.placeholder.name' | transloco"
							[disabled]="
								tariffForm.controls['name'].disabled || disableTariffForm
							"
							[required]="true"
							[showError]="
								tariffForm.controls['name'].invalid &&
								tariffForm.controls['name'].touched
							"
							formControlName="name"
							name="name"
						></ga-input>
					</div>
					<!-- Organização -->
					<div
						*ngIf="tariffForm.controls['organization']"
						class="form-group col-lg-4 col-sm-6"
					>
						<autocomplete-orgs
							[idSelected]="organization"
							[isRequired]="true"
							[isEnabled]="!disableTariffForm"
							(orgEmitter)="orgSelected($event)"
						></autocomplete-orgs>
					</div>
					<!-- Empresa -->
					<div
						*ngIf="tariffForm.controls['company']"
						class="form-group col-lg-4 col-sm-6"
					>
						<autocomplete-companies
							[idSelected]="tariffForm.controls['company'].value"
							[isEnabled]="!disableTariffForm"
							[organization]="organization"
							(companyEmitter)="companySelected($event)"
						></autocomplete-companies>
					</div>
					<!-- Distribuidora -->
					<div class="form-group col-lg-3 col-md-4 col-sm-12">
						<ga-selector
							[title]="'regulated-list.table.distributor' | transloco"
							[placeholder]="'global.select' | transloco"
							[isSearch]="true"
							[required]="true"
							[showError]="
								!tariffForm.controls['distributor'].valid &&
								tariffForm.controls['distributor'].touched
							"
							[options]="distributorsOptions"
							[disabled]="
								tariffForm.controls['distributor'].disabled || disableTariffForm
							"
							(ngModelChange)="changeDistributor($event)"
							formControlName="distributor"
							name="distributor"
						></ga-selector>
					</div>
					<!-- Tariff Type -->
					<div class="form-group col-lg-3 col-md-4 col-sm-6">
						<!-- (ngModelChange)="changeDistributor($event)" -->
						<ga-selector
							[title]="'regulated-list.table.modality' | transloco"
							[placeholder]="'global.select' | transloco"
							[isSearch]="true"
							[required]="true"
							[showError]="
								!tariffForm.controls['tariffType'].valid &&
								tariffForm.controls['tariffType'].touched
							"
							[options]="tariffTypeOptions"
							[disabled]="
								tariffForm.controls['tariffType'].disabled || disableTariffForm
							"
							formControlName="tariffType"
							name="tariffType"
							(ngModelChange)="changeTariffType($event)"
						></ga-selector>
					</div>
					<!-- subGroups -->
					<div class="form-group col-lg-2 col-md-4 col-sm-6">
						<!-- (ngModelChange)="changeDistributor($event)" -->
						<ga-selector
							[title]="'regulated-list.table.subgroup' | transloco"
							[placeholder]="'global.select' | transloco"
							[required]="true"
							[showError]="
								!tariffForm.controls['subGroup'].valid &&
								tariffForm.controls['subGroup'].touched
							"
							[options]="subGroupsOptions"
							[disabled]="
								tariffForm.controls['subGroup'].disabled || disableTariffForm
							"
							formControlName="subGroup"
							name="subGroup"
						></ga-selector>
					</div>
				</div>
				<!-- First Cycle -->
				<div
					class="settings col-lg-12 p-uns"
					*ngIf="type !== 'edit' && tariffForm.controls['tariffType'].value"
				>
					<div class="row p-uns col-lg-12">
						<hr class="separator" />
					</div>
					<h3 class="settings__title col-lg-12 p-uns">
						{{
							(firstCycle
								? "regulated-list.first-cycle-info"
								: "regulated-list.new-cycle-info"
							) | transloco
						}}
					</h3>
					<div class="form-group col-lg-3 col-sm-6">
						<ga-date-range-picker
							[title]="'regulated-list.form.tariff-validity' | transloco"
							[placeholder]="'00/00/0000 - 00/00/0000'"
							[showTime]="false"
							[showOptions]="false"
							[initialPeriod]="'custom'"
							[required]="true"
							[startWithCurrentDate]="false"
							[formControl]="dateRange"
							[disabled]="dateRange.disabled"
							[formattedDate]="formattedDate"
							[showError]="dateRange.invalid && dateRange.touched"
							(ngModelChange)="changeDate()"
						>
						</ga-date-range-picker>
					</div>
					<section formGroupName="fields">
						<!-- Apenas para TUSD Geração -->
						<div
							class="form-group col-lg-3 col-sm-6"
							*ngIf="tariffForm.controls['tariffType'].value == 16"
						>
							<ga-input
								[title]="'contract.instance.demand-rate' | transloco"
								[placeholder]="'0'"
								[rAddon]="'contract.instance.units.currency-kw' | transloco"
								[type]="'number'"
								[showError]="
									tariffForm.get('fields.TUSDGenerationRate')?.invalid &&
									tariffForm.get('fields.TUSDGenerationRate')?.touched
								"
								[disabled]="
									tariffForm.get('fields.TUSDGenerationRate')?.disabled || false
								"
								[required]="true"
								formControlName="TUSDGenerationRate"
							></ga-input>
						</div>
						<div
							class="form-group col-lg-3 col-sm-6"
							*ngIf="[5, 17].includes(tariffForm.controls['tariffType'].value)"
						>
							<ga-input
								[title]="'contract.instance.energy-rate' | transloco"
								[placeholder]="'0'"
								[rAddon]="'contract.instance.units.currency-kwh' | transloco"
								[type]="'number'"
								[showError]="
									tariffForm.get('fields.energyRate')?.invalid &&
									tariffForm.get('fields.energyRate')?.touched
								"
								[disabled]="
									tariffForm.get('fields.energyRate')?.disabled || false
								"
								[required]="true"
								formControlName="energyRate"
							></ga-input>
						</div>
						<!-- Apenas para baixa tensão branca -->
						<div
							class="form-group col-lg-3 col-sm-6"
							*ngIf="tariffForm.controls['tariffType'].value == 8"
						>
							<ga-input
								[title]="'contract.instance.peak-energy-rate' | transloco"
								[placeholder]="'0'"
								[rAddon]="'contract.instance.units.currency-kwh' | transloco"
								[type]="'number'"
								[showError]="
									tariffForm.get('fields.peakEnergyRateSeason1')?.invalid &&
									tariffForm.get('fields.peakEnergyRateSeason1')?.touched
								"
								[disabled]="
									tariffForm.get('fields.peakEnergyRateSeason1')?.disabled ||
									false
								"
								[required]="true"
								formControlName="peakEnergyRateSeason1"
							></ga-input>
						</div>
						<div
							class="form-group col-lg-3 col-sm-6"
							*ngIf="tariffForm.controls['tariffType'].value == 8"
						>
							<ga-input
								[title]="'contract.instance.offpeak-energy-rate' | transloco"
								[placeholder]="'0'"
								[rAddon]="'contract.instance.units.currency-kwh' | transloco"
								[type]="'number'"
								[showError]="
									tariffForm.get('fields.offPeakEnergyRateSeason1')?.invalid &&
									tariffForm.get('fields.offPeakEnergyRateSeason1')?.touched
								"
								[disabled]="
									tariffForm.get('fields.offPeakEnergyRateSeason1')?.disabled ||
									false
								"
								[required]="true"
								formControlName="offPeakEnergyRateSeason1"
							></ga-input>
						</div>
						<div
							class="form-group col-lg-3 col-sm-6"
							*ngIf="tariffForm.controls['tariffType'].value == 8"
						>
							<ga-input
								[title]="
									'contract.instance.intermediate-energy-rate' | transloco
								"
								[placeholder]="'0'"
								[rAddon]="'contract.instance.units.currency-kwh' | transloco"
								[type]="'number'"
								[showError]="
									tariffForm.get('fields.intermediatePeakEnergyRateSeason1')
										?.invalid &&
									tariffForm.get('fields.intermediatePeakEnergyRateSeason1')
										?.touched
								"
								[disabled]="
									tariffForm.get('fields.intermediatePeakEnergyRateSeason1')
										?.disabled || false
								"
								[required]="true"
								formControlName="intermediatePeakEnergyRateSeason1"
							></ga-input>
						</div>
						<!-- Apenas para baixa tensão branca -->
						<!-- Apenas para media/alta verde e azul -->
						<div
							class="form-group col-lg-3 col-sm-6"
							*ngIf="[2, 3].includes(tariffForm.controls['tariffType'].value)"
						>
							<ga-time-picker
								[title]="'contract.instance.peak-hour' | transloco"
								[placeholder]="'00:00 - 00:00'"
								[isRange]="true"
								(changes)="
									onTimeChanged($event, {
										start: 'fields.startPeakTime',
										end: 'fields.endPeakTime'
									})
								"
								[required]="true"
								[showError]="
									(tariffForm.get('fields.startPeakTime')?.invalid &&
										tariffForm.get('fields.startPeakTime')?.touched) ||
									(tariffForm.get('fields.endPeakTime')?.invalid &&
										tariffForm.get('fields.endPeakTime')?.touched)
								"
								[disabled]="
									tariffForm.get('fields.startPeakTime')?.disabled ||
									tariffForm.get('fields.endPeakTime')?.disabled ||
									false
								"
								[rangeTime]="
									!tariffForm.get('fields.startPeakTime')?.value &&
									!tariffForm.get('fields.endPeakTime')?.value
										? []
										: [
												tariffForm.get('fields.startPeakTime')?.value,
												tariffForm.get('fields.endPeakTime')?.value
										  ]
								"
							></ga-time-picker>
						</div>
						<section
							*ngIf="[2, 3].includes(tariffForm.controls['tariffType'].value)"
						>
							<div class="row p-uns col-lg-12">
								<hr class="separator" />
							</div>
							<h3 class="basic-data__title col-lg-12">
								{{ "contract.instance.energy-rate" | transloco }}
							</h3>
							<div class="row p-uns col-lg-12">
								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="
											'contract.instance.peak-transmition-rate-season-2'
												| transloco
										"
										[placeholder]="'0'"
										[rAddon]="
											'contract.instance.units.currency-kwh' | transloco
										"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.peakTransmitionRateSeason1')
												?.invalid &&
											tariffForm.get('fields.peakTransmitionRateSeason1')
												?.touched
										"
										[disabled]="
											tariffForm.get('fields.peakTransmitionRateSeason1')
												?.disabled || false
										"
										[required]="true"
										formControlName="peakTransmitionRateSeason1"
									></ga-input>
								</div>

								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="
											'contract.instance.peak-energy-rate-season-2' | transloco
										"
										[placeholder]="'0'"
										[rAddon]="
											'contract.instance.units.currency-kwh' | transloco
										"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.peakEnergyRateSeason1')?.invalid &&
											tariffForm.get('fields.peakEnergyRateSeason1')?.touched
										"
										[disabled]="
											tariffForm.get('fields.peakEnergyRateSeason1')
												?.disabled || false
										"
										[required]="true"
										formControlName="peakEnergyRateSeason1"
									></ga-input>
								</div>

								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="
											'contract.instance.off-peak-transmition-rate-season-2'
												| transloco
										"
										[placeholder]="'0'"
										[rAddon]="
											'contract.instance.units.currency-kwh' | transloco
										"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.offPeakTransmitionRateSeason1')
												?.invalid &&
											tariffForm.get('fields.offPeakTransmitionRateSeason1')
												?.touched
										"
										[disabled]="
											tariffForm.get('fields.offPeakTransmitionRateSeason1')
												?.disabled || false
										"
										[required]="true"
										formControlName="offPeakTransmitionRateSeason1"
									></ga-input>
								</div>

								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="
											'contract.instance.off-peak-energy-rate-season-2'
												| transloco
										"
										[placeholder]="'0'"
										[rAddon]="
											'contract.instance.units.currency-kwh' | transloco
										"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.offPeakEnergyRateSeason1')
												?.invalid &&
											tariffForm.get('fields.offPeakEnergyRateSeason1')?.touched
										"
										[disabled]="
											tariffForm.get('fields.offPeakEnergyRateSeason1')
												?.disabled || false
										"
										[required]="true"
										formControlName="offPeakEnergyRateSeason1"
									></ga-input>
								</div>
							</div>
						</section>
						<!-- Apenas para media/alta verde e azul -->
						<div
							class="form-group col-lg-3 col-sm-6"
							[ngClass]="{
								'flex-end': tariffForm.controls['tariffType'].value === 5
							}"
							*ngIf="
								[2, 3, 5, 8].includes(tariffForm.controls['tariffType'].value)
							"
						>
							<ga-checkbox
								[title]="'regulated-list.form.apply-tariff' | transloco"
								[isMultiple]="false"
								[disabled]="
									tariffForm.get('fields.tariffFlagSection')?.disabled || false
								"
								formControlName="tariffFlagSection"
								name="tariffFlagSection"
							></ga-checkbox>
						</div>

						<section
							*ngIf="[2, 3].includes(tariffForm.controls['tariffType'].value)"
						>
							<div class="row p-uns col-lg-12">
								<hr class="separator" />
							</div>
							<h3 class="basic-data__title col-lg-12">
								{{ "contract.instance.demand-title" | transloco }}
							</h3>
							<div class="row p-uns col-lg-12">
								<ng-container
									*ngIf="tariffForm.controls['tariffType'].value === 3"
								>
									<div class="form-group col-lg-3 col-sm-6">
										<ga-input
											[title]="'contract.instance.peak-energy-rate' | transloco"
											[placeholder]="'0'"
											[rAddon]="
												'contract.instance.units.currency-kw' | transloco
											"
											[type]="'number'"
											[showError]="
												tariffForm.get('fields.peakDemandRate')?.invalid &&
												tariffForm.get('fields.peakDemandRate')?.touched
											"
											[disabled]="
												tariffForm.get('fields.peakDemandRate')?.disabled ||
												false
											"
											[required]="true"
											formControlName="peakDemandRate"
										></ga-input>
									</div>
									<div class="form-group col-lg-3 col-sm-6">
										<ga-input
											[title]="
												'contract.instance.offpeak-energy-rate' | transloco
											"
											[placeholder]="'0'"
											[rAddon]="
												'contract.instance.units.currency-kw' | transloco
											"
											[type]="'number'"
											[showError]="
												tariffForm.get('fields.offPeakDemandRate')?.invalid &&
												tariffForm.get('fields.offPeakDemandRate')?.touched
											"
											[disabled]="
												tariffForm.get('fields.offPeakDemandRate')?.disabled ||
												false
											"
											[required]="true"
											formControlName="offPeakDemandRate"
										></ga-input>
									</div>
									<div class="form-group col-lg-3 col-sm-6">
										<ga-input
											[title]="
												'contract.instance.over-peak-demand-rate' | transloco
											"
											[placeholder]="'0'"
											[rAddon]="
												'contract.instance.units.currency-kw' | transloco
											"
											[type]="'number'"
											[showError]="
												tariffForm.get('fields.overPeakDemandRate')?.invalid &&
												tariffForm.get('fields.overPeakDemandRate')?.touched
											"
											[disabled]="
												tariffForm.get('fields.overPeakDemandRate')?.disabled ||
												false
											"
											[required]="true"
											formControlName="overPeakDemandRate"
										></ga-input>
									</div>
									<div class="form-group col-lg-3 col-sm-6">
										<ga-input
											[title]="
												'contract.instance.over-off-peak-demand-rate'
													| transloco
											"
											[placeholder]="'0'"
											[rAddon]="
												'contract.instance.units.currency-kw' | transloco
											"
											[type]="'number'"
											[showError]="
												tariffForm.get('fields.overOffPeakDemandRate')
													?.invalid &&
												tariffForm.get('fields.overOffPeakDemandRate')?.touched
											"
											[disabled]="
												tariffForm.get('fields.overOffPeakDemandRate')
													?.disabled || false
											"
											[required]="true"
											formControlName="overOffPeakDemandRate"
										></ga-input>
									</div>
								</ng-container>
								<ng-container
									*ngIf="tariffForm.controls['tariffType'].value === 2"
								>
									<div class="form-group col-lg-3 col-sm-6">
										<ga-input
											[title]="'contract.instance.demand-rate' | transloco"
											[placeholder]="'0'"
											[rAddon]="
												'contract.instance.units.currency-kw' | transloco
											"
											[type]="'number'"
											[showError]="
												tariffForm.get('fields.demandRate')?.invalid &&
												tariffForm.get('fields.demandRate')?.touched
											"
											[disabled]="
												tariffForm.get('fields.demandRate')?.disabled || false
											"
											[required]="true"
											formControlName="demandRate"
										></ga-input>
									</div>

									<div class="form-group col-lg-3 col-sm-6">
										<ga-input
											[title]="'contract.instance.over-demand-rate' | transloco"
											[placeholder]="'0'"
											[rAddon]="
												'contract.instance.units.currency-kw' | transloco
											"
											[type]="'number'"
											[showError]="
												tariffForm.get('fields.overDemandRate')?.invalid &&
												tariffForm.get('fields.overDemandRate')?.touched
											"
											[disabled]="
												tariffForm.get('fields.overDemandRate')?.disabled ||
												false
											"
											[required]="true"
											formControlName="overDemandRate"
										></ga-input>
									</div>
								</ng-container>

								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="'contract.instance.tolerance-rate' | transloco"
										[placeholder]="'regulated-list.form.value' | transloco"
										[rAddon]="'%'"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.toleranceRate')?.invalid &&
											tariffForm.get('fields.toleranceRate')?.touched
										"
										[disabled]="
											tariffForm.get('fields.toleranceRate')?.disabled || false
										"
										[required]="true"
										formControlName="toleranceRate"
									></ga-input>
								</div>

								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="'contract.instance.min-power-factor' | transloco"
										[placeholder]="'0' | transloco"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.minPowerFactor')?.invalid &&
											tariffForm.get('fields.minPowerFactor')?.touched
										"
										[disabled]="
											tariffForm.get('fields.minPowerFactor')?.disabled || false
										"
										[required]="true"
										formControlName="minPowerFactor"
									></ga-input>
								</div>
								<div
									class="form-group col-lg-3 col-sm-6"
									*ngIf="
										[2, 3].includes(tariffForm.controls['tariffType'].value)
									"
								>
									<ga-time-picker
										[title]="'contract.instance.capacitive-hour' | transloco"
										[placeholder]="'00:00 - 00:00'"
										[isRange]="true"
										(changes)="
											onTimeChanged($event, {
												start: 'fields.capacitiveStartHour',
												end: 'fields.capacitiveEndHour'
											})
										"
										[required]="true"
										[showError]="
											(tariffForm.get('fields.capacitiveStartHour')?.invalid &&
												tariffForm.get('fields.capacitiveStartHour')
													?.touched) ||
											(tariffForm.get('fields.capacitiveEndHour')?.invalid &&
												tariffForm.get('fields.capacitiveEndHour')?.touched)
										"
										[disabled]="
											tariffForm.get('fields.capacitiveStartHour')?.disabled ||
											tariffForm.get('fields.capacitiveEndHour')?.disabled ||
											false
										"
										[rangeTime]="
											!tariffForm.get('fields.capacitiveStartHour')?.value &&
											!tariffForm.get('fields.capacitiveEndHour')?.value
												? []
												: [
														tariffForm.get('fields.capacitiveStartHour')?.value,
														tariffForm.get('fields.capacitiveEndHour')?.value
												  ]
										"
									></ga-time-picker>
								</div>
							</div>
						</section>
						<section *ngIf="tariffForm.controls['tariffType'].value == 8">
							<div class="row p-uns col-lg-12">
								<hr class="separator" />
							</div>
							<h3 class="settings__title col-lg-12">
								<span>
									{{ "regulated-list.tariff-stations" | transloco }}
								</span>
								<ga-helper
									[text]="'regulated-list.tariff-stations-tip' | transloco"
									[icon]="'fa-solid fa-circle-info'"
									[iconSize]="14"
									[iconColor]="'#A3A3A3'"
									[tooltipLocale]="'above'"
								></ga-helper>
							</h3>
							<div class="row p-uns col-lg-12">
								<div class="form-group col-lg-3 col-sm-6">
									<ga-time-picker
										[title]="'contract.instance.peak-hour' | transloco"
										[placeholder]="'00:00 - 00:00'"
										[isRange]="true"
										[required]="true"
										(changes)="
											onTimeChanged($event, {
												start: 'fields.startPeakTime',
												end: 'fields.endPeakTime'
											})
										"
										[showError]="
											(tariffForm.get('fields.startPeakTime')?.invalid &&
												tariffForm.get('fields.startPeakTime')?.touched) ||
											(tariffForm.get('fields.endPeakTime')?.invalid &&
												tariffForm.get('fields.endPeakTime')?.touched)
										"
										[disabled]="
											tariffForm.get('fields.startPeakTime')?.disabled ||
											tariffForm.get('fields.endPeakTime')?.disabled ||
											false
										"
										[rangeTime]="
											!tariffForm.get('fields.startPeakTime')?.value &&
											!tariffForm.get('fields.endPeakTime')?.value
												? []
												: [
														tariffForm.get('fields.startPeakTime')?.value,
														tariffForm.get('fields.endPeakTime')?.value
												  ]
										"
									></ga-time-picker>
								</div>
								<div class="form-group col-lg-3 col-sm-6">
									<ga-time-picker
										[title]="
											'contract.instance.intermediate-hour-1' | transloco
										"
										[tooltip]="
											'contract.instance.intermediate-hour-1-tip' | transloco
										"
										[placeholder]="'00:00 - 00:00'"
										[isRange]="true"
										[required]="true"
										(changes)="
											onTimeChanged($event, {
												start: 'fields.startFirstIntermediatePeakTime',
												end: 'fields.endFirstIntermediatePeakTime'
											})
										"
										[showError]="
											(tariffForm.get('fields.startFirstIntermediatePeakTime')
												?.invalid &&
												tariffForm.get('fields.startFirstIntermediatePeakTime')
													?.touched) ||
											(tariffForm.get('fields.endFirstIntermediatePeakTime')
												?.invalid &&
												tariffForm.get('fields.endFirstIntermediatePeakTime')
													?.touched)
										"
										[disabled]="
											tariffForm.get('fields.startFirstIntermediatePeakTime')
												?.disabled ||
											tariffForm.get('fields.endFirstIntermediatePeakTime')
												?.disabled ||
											false
										"
										[rangeTime]="
											!tariffForm.get('fields.startFirstIntermediatePeakTime')
												?.value &&
											!tariffForm.get('fields.endFirstIntermediatePeakTime')
												?.value
												? []
												: [
														tariffForm.get(
															'fields.startFirstIntermediatePeakTime'
														)?.value,
														tariffForm.get(
															'fields.endFirstIntermediatePeakTime'
														)?.value
												  ]
										"
									></ga-time-picker>
								</div>
								<div class="form-group col-lg-3 col-sm-6">
									<ga-time-picker
										[title]="
											'contract.instance.intermediate-hour-2' | transloco
										"
										[tooltip]="
											'contract.instance.intermediate-hour-2-tip' | transloco
										"
										[placeholder]="'00:00 - 00:00'"
										[isRange]="true"
										[required]="true"
										(changes)="
											onTimeChanged($event, {
												start: 'fields.startLastIntermediatePeakTime',
												end: 'fields.endLastIntermediatePeakTime'
											})
										"
										[showError]="
											(tariffForm.get('fields.startLastIntermediatePeakTime')
												?.invalid &&
												tariffForm.get('fields.startLastIntermediatePeakTime')
													?.touched) ||
											(tariffForm.get('fields.endLastIntermediatePeakTime')
												?.invalid &&
												tariffForm.get('fields.endLastIntermediatePeakTime')
													?.touched)
										"
										[disabled]="
											tariffForm.get('fields.startLastIntermediatePeakTime')
												?.disabled ||
											tariffForm.get('fields.endLastIntermediatePeakTime')
												?.disabled ||
											false
										"
										[rangeTime]="
											!tariffForm.get('fields.startLastIntermediatePeakTime')
												?.value &&
											!tariffForm.get('fields.endLastIntermediatePeakTime')
												?.value
												? []
												: [
														tariffForm.get(
															'fields.startLastIntermediatePeakTime'
														)?.value,
														tariffForm.get('fields.endLastIntermediatePeakTime')
															?.value
												  ]
										"
									></ga-time-picker>
								</div>
							</div>
						</section>
						<section *ngIf="tariffForm.controls['tariffType'].value == 16">
							<div class="row p-uns col-lg-12">
								<hr class="separator" />
							</div>
							<h3 class="basic-data__title col-lg-12">
								{{ "contract.instance.discounts-title" | transloco }}
							</h3>
							<div class="row p-uns col-lg-12">
								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="'contract.instance.tusd-discount' | transloco"
										[placeholder]="'regulated-list.form.value' | transloco"
										[rAddon]="'%'"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.TUSDDGenerationDiscount')
												?.invalid &&
											tariffForm.get('fields.TUSDDGenerationDiscount')?.touched
										"
										[disabled]="
											tariffForm.get('fields.TUSDDGenerationDiscount')
												?.disabled || false
										"
										[required]="true"
										formControlName="TUSDDGenerationDiscount"
									></ga-input>
								</div>
							</div>
						</section>

						<!-- section de impostos -->
						<section>
							<div class="row p-uns col-lg-12">
								<hr class="separator" />
							</div>
							<h3 class="basic-data__title col-lg-12">
								{{ "contract.instance.taxes-title" | transloco }}
							</h3>
							<div
								class="row p-uns col-lg-12"
								*ngIf="tariffForm.controls['tariffType'].value == 17"
							>
								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="'contract.instance.vat' | transloco"
										[placeholder]="'regulated-list.form.value' | transloco"
										[rAddon]="'%'"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.vat')?.invalid &&
											tariffForm.get('fields.vat')?.touched
										"
										[disabled]="tariffForm.get('fields.vat')?.disabled || false"
										[required]="true"
										formControlName="vat"
									></ga-input>
								</div>
							</div>
							<div
								class="row p-uns col-lg-12"
								*ngIf="tariffForm.controls['tariffType'].value !== 17"
							>
								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="'contract.instance.pis' | transloco"
										[placeholder]="'regulated-list.form.value' | transloco"
										[rAddon]="'%'"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.pis')?.invalid &&
											tariffForm.get('fields.pis')?.touched
										"
										[disabled]="tariffForm.get('fields.pis')?.disabled || false"
										[required]="true"
										formControlName="pis"
									></ga-input>
								</div>
								<div class="form-group col-lg-3 col-sm-6">
									<ga-input
										[title]="'contract.instance.cofins' | transloco"
										[placeholder]="'regulated-list.form.value' | transloco"
										[rAddon]="'%'"
										[type]="'number'"
										[showError]="
											tariffForm.get('fields.cofins')?.invalid &&
											tariffForm.get('fields.cofins')?.touched
										"
										[disabled]="
											tariffForm.get('fields.cofins')?.disabled || false
										"
										[required]="true"
										formControlName="cofins"
									></ga-input>
								</div>
								<div
									class="form-group col-lg-6 col-sm-12"
									[ngClass]="{ 'flex-end': true }"
								>
									<ga-checkbox
										[title]="
											'contract.instance.no-icms-on-piscofins-base' | transloco
										"
										[isMultiple]="false"
										[disabled]="
											tariffForm.get('fields.noIcmsOnPisCofinsBase')
												?.disabled || false
										"
										formControlName="noIcmsOnPisCofinsBase"
										name="noIcmsOnPisCofinsBase"
									></ga-checkbox>
								</div>
								<div class="row col-lg-12 p-uns">
									<ng-container formArrayName="icms">
										<div
											class="row"
											*ngFor="
												let icmsItem of icmsFormArray.controls;
												let i = index
											"
											[formGroupName]="i"
										>
											<div class="form-group col-lg-3 col-sm-6">
												<ga-input
													[title]="
														'contract.instance.start-icms-range' | transloco
													"
													[placeholder]="
														'regulated-list.form.value' | transloco
													"
													[rAddon]="'contract.instance.units.kwh' | transloco"
													[type]="'number'"
													[showError]="
														icmsFormArray.controls[i].get('to')?.invalid &&
														icmsFormArray.controls[i].get('to')?.touched
													"
													[disabled]="
														icmsFormArray.controls[i].get('to')?.disabled ||
														false
													"
													[required]="true"
													formControlName="to"
													name="to"
												></ga-input>
											</div>
											<div class="form-group col-lg-3 col-sm-6">
												<ga-input
													[title]="
														'contract.instance.end-icms-range' | transloco
													"
													[placeholder]="
														'regulated-list.form.value' | transloco
													"
													[rAddon]="'contract.instance.units.kwh' | transloco"
													[type]="'number'"
													[showError]="
														icmsFormArray.controls[i].get('from')?.invalid &&
														icmsFormArray.controls[i].get('from')?.touched
													"
													[disabled]="
														icmsFormArray.controls[i].get('from')?.disabled ||
														false
													"
													[required]="true"
													formControlName="from"
													name="from"
												></ga-input>
											</div>
											<div class="form-group col-lg-3 col-sm-6">
												<ga-input
													[title]="'contract.instance.icms-value' | transloco"
													[placeholder]="
														'regulated-list.form.value' | transloco
													"
													[rAddon]="'%' | transloco"
													[type]="'number'"
													[showError]="
														icmsFormArray.controls[i].get('percent')?.invalid &&
														icmsFormArray.controls[i].get('percent')?.touched
													"
													[disabled]="
														icmsFormArray.controls[i].get('percent')
															?.disabled || false
													"
													[required]="true"
													formControlName="percent"
													name="percent"
												></ga-input>
											</div>
											<div class="form-group col-sm-2 flex-down">
												<button
													type="button"
													[disabled]="tariffForm.status === 'DISABLED'"
													(click)="removeICMS(i)"
													class="btn trash"
												>
													<i class="fa-regular fa-trash"></i>
												</button>
											</div>
										</div>
									</ng-container>
								</div>
								<div>
									<button
										*ngIf="tariffForm.status !== 'DISABLED'"
										class="btn btn-group btn-default btn-default-ga addition-btn-div"
										type="button"
										(click)="addIcmsItem()"
									>
										{{ "contract.instance.add-icms" | transloco }}
									</button>
								</div>
							</div>
						</section>
					</section>
				</div>

				<!-- ------------------------- button group ------------------------- -->

				<div class="button-group flex-row">
					<div class="row p-uns col-lg-12">
						<hr class="separator" />
					</div>
					<div class="btn-toolbar" role="toolbar">
						<div class="btn-group" role="group">
							<button class="btn btn-default" (click)="backTo()">
								{{
									(type == "view" ? "global.return" : "global.cancel")
										| transloco
								}}
							</button>
						</div>
						<div *ngIf="type !== 'view'" class="btn-group" role="group">
							<button
								[disabled]="tariffForm.invalid || isSaving"
								class="btn btn-primary btn-color-ga"
								type="submit"
							>
								<span>
									{{ "global.save" | transloco }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
