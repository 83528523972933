import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AcceptTermsComponent } from './accept-terms/accept-terms-notification';
import { ResponseNotificationComponent } from './response/response-notification';

type Message = {
	msg: string;
	severity?: string;
};

@Injectable({ providedIn: 'root' })
export class NotificationService {
	constructor(private _snackBar: MatSnackBar) {}

	success({ msg }: Message) {
		this._snackBar.openFromComponent(ResponseNotificationComponent, {
			data: { msg, type: 'success', icon: 'fa-circle-check' },
			panelClass: ['success', 'notify'],
			horizontalPosition: 'right',
			verticalPosition: 'top',
			duration: 5000,
		});
	}

	warning({ msg }: Message) {
		this._snackBar.openFromComponent(ResponseNotificationComponent, {
			data: { msg, type: 'warning', icon: 'fa-exclamation-circle' },
			panelClass: ['warning', 'notify'],
			horizontalPosition: 'right',
			verticalPosition: 'top',
			duration: 5000,
		});
	}

	error({ msg }: Message) {
		const snackBarRef = this._snackBar.openFromComponent(
			ResponseNotificationComponent,
			{
				data: { msg, type: 'error', icon: 'fa-circle-x' },
				panelClass: ['error', 'notify'],
				horizontalPosition: 'right',
				verticalPosition: 'top',
				duration: 5000,
			}
		);
		snackBarRef
			.onAction()
			.pipe(take(1))
			.subscribe(() => {
				snackBarRef.dismiss();
			});
	}

	showErrorMessages(responseErrors: any) {
		const data = responseErrors.data
			? responseErrors.data.Error
			: responseErrors.error?.Error;
		let msg = 'Erro ao conectar ao servidor';
		if (!data) {
			this.error({ msg });
			return;
		}
		if (!data?.Errors) {
			this.error({ msg });
			return;
		}
		Object.keys(data?.Errors).forEach((key) => {
			msg = data?.Errors[key][0]?.message;
			this.error({ msg });
		});
	}

	acceptTerms() {
		return this._snackBar.openFromComponent(AcceptTermsComponent, {
			panelClass: ['accept-custom'],
		});
	}
}
