import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { ColumnType, DataTableList } from './model/datatable.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PaginatorIntl } from './service/paginator.service';
import { GaDataTableNewService } from './service/datatable.service';
import { Action } from './model/action.model';
import { GADropdownCustom } from './model/dropdown-custom.model';
import { Observable, Subscription } from 'rxjs';
import { MixPanelService } from '../../../app/shared/services/mixpanel.service';
@Component({
	selector: 'ga-datatable-new',
	templateUrl: './ga-datatable-new.component.html',
	styleUrls: ['./ga-datatable-new.component.scss'],
	animations: [
		trigger('detailExpand', [
			state(
				'collapsed',
				style({ height: '0px', minHeight: '0', display: 'none' })
			),
			state('expanded', style({ minHeight: '60px', display: 'flex' })),
			transition(
				'expanded <=> collapsed',
				animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			),
		]),
	],
})
export class GaDatatableNewComponent
	implements AfterViewInit, OnChanges, OnDestroy
{
	@ViewChild('mainPaginator') paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;
	id!: number;
	@Input() element$: Observable<DataTableList> = new Observable();
	@Input() tableName!: string;
	@Input() tableTitle = 'data-table.registration-list';
	@Input() hideShadow = false;
	@Input() hideSearch = false;
	@Input() hideLoading = false;
	@Input() filterMultiple = false;
	@Input() showSubcolumTitle = false;
	@Input() showSubtablePaginator = false;
	@Input() showElements = true;

	@Output() onAction: EventEmitter<Action> = new EventEmitter();
	@Output() onDropdown: EventEmitter<GADropdownCustom> = new EventEmitter();
	@Output() renderDataChange: EventEmitter<Action> = new EventEmitter();
	@Output() rowExpand: EventEmitter<Action> = new EventEmitter();
	subscriptions: Subscription[] = [];

	searchInput: FormControl = new FormControl();
	firstColumn = '';
	table: MatTableDataSource<any> = new MatTableDataSource();
	displayedColumns: string[] = [];
	displayedSubColumns: string[] = [];
	sizeIcon = 22;
	columns: ColumnType[] = [];
	subColumns: ColumnType[] = [];
	propertyToExpand!: string;
	activeFilters: any = [];
	badge = '';

	constructor(
		private cdr: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
		private paginatorService: PaginatorIntl,
		private tableService: GaDataTableNewService,
		private mixpanelService: MixPanelService
	) {
		this.createRandomId();
	}

	ngOnChanges() {
		const subscription = this.element$.subscribe((datatable: DataTableList) => {
			this.initTable(datatable);
			this.tablePaginatorLabels();
		});
		this.subscriptions.push(subscription);
	}

	ngAfterViewInit() {
		this.search();
		this.changeAction();
		this.changeDropdownCustom();
		this.registerChangePageOnSearch();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	private tablePaginatorLabels() {
		if (!this.paginator) return;
		this.paginator._intl.firstPageLabel =
			this.paginatorService.tablePaginator().firstPage;
		this.paginator._intl.lastPageLabel =
			this.paginatorService.tablePaginator().lastPage;
		this.paginator._intl.itemsPerPageLabel =
			this.paginatorService.tablePaginator().itemsPerPage;
		this.paginator._intl.nextPageLabel =
			this.paginatorService.tablePaginator().nextPage;
		this.paginator._intl.previousPageLabel =
			this.paginatorService.tablePaginator().previousPage;
		this.paginator._intl.getRangeLabel = this.paginatorService.rangeLabel;
	}

	private initTable(datatable: DataTableList) {
		this.table.data = datatable.data;
		this.badge = datatable.badge || '';
		this.columns = datatable.column;
		this.firstColumn = datatable.column?.length ? datatable.column[0].name : '';
		this.displayedColumns = this.columns.map((column) => column.name);
		this.subColumns = datatable.sub || [];
		this.displayedSubColumns = this.subColumns.map((column) => column.name);
		/* load paginator inside of ng-container TODO:Improve this */
		setTimeout(() => (this.table.paginator = this.paginator));
		setTimeout(() => (this.table.sort = this.sort));
		if (datatable.filter) this.table.filterPredicate = datatable.filter;
		this.table.sort = this.sort;
		this.table.sortingDataAccessor = (item: any, property: string) => {
			return this.tableService.sortByType(item, property, this.columns);
		};

		this.emitChangePage();
		this.cdr.detectChanges();
	}

	private search() {
		const subscription = this.searchInput.valueChanges
			.pipe(
				tap((value) => {
					if (this.filterMultiple) {
						this.setFilters({ column: 'all', value });
						return;
					}
					this.table.filter = value;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	public subRows(row: any): any[] {
		return Array.isArray(row[this.propertyToExpand])
			? row[this.propertyToExpand]
			: [];
	}

	private applyFilter() {
		this.table.filter = this.activeFilters;
	}

	setFilters(filter) {
		const f = this.activeFilters.find((af) => af.column == filter.column);

		if (!f) {
			this.activeFilters.push(filter);
		} else f.value = filter.value;

		this.applyFilter();
	}

	public getSafeHtml(content): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	public isExpandedTable(column: ColumnType, row: any) {
		if (column.isExpanded) this.propertyToExpand = <string>column.expandedName;
		return row[<string>column.expandedName];
	}

	changeAction() {
		const subscription = this.tableService.$action.subscribe(
			(res: { action; id }) => {
				if (res.id !== this.id) return;
				this.mixpanelService.mixPanelEvent({
					type: `${this.tableName.toLocaleLowerCase()}_kebab_${
						res.action.type
					}`,
					object: {},
				});
				this.onAction.emit(res.action);
			}
		);
		this.subscriptions.push(subscription);
	}

	changeDropdownCustom() {
		const subscription = this.tableService.$dropdownCustom.subscribe((res) => {
			this.onDropdown.emit(res);
		});
		this.subscriptions.push(subscription);
	}

	emitChangePage() {
		setTimeout(() => {
			const renderData = Reflect.get(this.table, '_renderData');
			this.renderDataChange.emit(renderData._value);
		}, 0);
	}

	registerChangePageOnSearch() {
		const subscription = this.searchInput.valueChanges
			.pipe(
				debounceTime(600),
				tap(() => {
					this.emitChangePage();
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	exportTable() {
		if (this.table.data.length === 0) return;
		this.mixpanelService.mixPanelEvent({
			type: `export_${this.tableName.toLocaleLowerCase()}`,
			object: {},
		});
		const json = this.tableService.prepareJsonToXlsx(
			this.columns,
			this.table.filteredData
		);
		this.tableService.exportJsonToExcel(json, this.tableName);
	}

	expandRow(row: any) {
		row.isExpanded = !row.isExpanded;
		this.table.data.forEach((tableRow) => {
			if (tableRow !== row) tableRow.isExpanded = false;
		});
		this.rowExpand.emit(row.isExpanded ? row : false);
	}

	createRandomId(): void {
		this.id = Math.floor(Math.random() * 10000);
	}
}
