import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import { Observable, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { OrganizationSmall } from 'webapp/app/shared/models/organization.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { TagService } from 'webapp/app/shared/services/tag.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { TagsManagerService } from './tags-manager.service';

import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
@Component({
	selector: 'tags-manager',
	templateUrl: './tags-manager.component.html',
	styleUrls: ['./tags-manager.component.scss'],
})
export class TagsManagerComponent {
	organizationId!: number;
	displayEl$: Observable<DataTableList> = new Observable();
	dialogRef!: MatDialogRef<any>;

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		private tagService: TagService,
		public dialog: MatDialog,
		private i18n: TranslocoService,
		private notification: NotificationService,
		private tagsManagerService: TagsManagerService,
		private mixpanel: MixPanelService
	) {}

	buildTable() {
		this.displayEl$ = from(
			this.tagService.listTagsandObjectsByOrganization({
				organization: this.organizationId,
			})
		).pipe(
			map((tags) => {
				const datatable: DataTableList = {} as DataTableList;
				datatable.data = this.tagsManagerService.renderListToTable(tags);
				datatable.column = this.tagsManagerService.columnHeaders;
				datatable.badge =
					tags.length === 1
						? this.i18n.translate('tags.table.tag')
						: this.i18n.translate('tags.table.tags');
				datatable.filter = this.tagsManagerService.filterTable;
				return datatable;
			})
		);
	}

	orgSelected(organization: OrganizationSmall) {
		if (!organization?.id) return;
		this.organizationId = organization.id;
		this.buildTable();
	}

	onActionCliked($event: Action) {
		switch ($event.type) {
			case 'edit':
				this.toEdit(<number>$event.id);
				break;
			case 'delete':
				this.removeDialog(<number>$event.id);
				break;
			default:
				break;
		}
	}

	createTag() {
		this.mixPanelEvent('click_button_tags_create');
		this.$state.transitionTo('main.tags-new', {
			organizationId: this.organizationId,
		});
	}

	toEdit(id: number) {
		this.$state.transitionTo('main.tags-edit', {
			id: id,
			organizationId: this.organizationId,
		});
	}

	removeDialog(id: number) {
		const confirmDialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		confirmDialogModel.icon = 'error';
		confirmDialogModel.iconType = 'error';
		confirmDialogModel.title = this.i18n.translate('company.msg-delete');
		confirmDialogModel.message = this.i18n.translate('company.msg-delete-2');
		confirmDialogModel.btnError = true;
		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: confirmDialogModel,
			width: '407px',
		});

		this.dialogRef.componentInstance.confirm
			.pipe(
				tap(() => {
					this.tagService
						.deleteTag(id)
						.then(() => {
							this.notification.success({
								msg: this.i18n.translate('tags.delete-success'),
							});
							this.closeDialog();
							this.buildTable();
						})
						.catch((error) => {
							this.notification.showErrorMessages(error);
							this.closeDialog();
						});
				})
			)
			.subscribe();
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	closeDialog() {
		this.dialogRef.close();
	}
}

export const ng2TagsComponent = {
	name: 'tagsManager',
	def: downgradeComponent({
		component: TagsManagerComponent,
		propagateDigest: true,
	}),
};
