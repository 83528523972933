<label *ngIf="title">
	<span>{{ title }}</span>
	<span *ngIf="required" class="required">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<ngx-dropzone
	id="dropzone-{{ id }}"
	[disabled]="disabled"
	(blur)="onTouch()"
	(change)="onSelect($event)"
	class="flex-col"
	[multiple]="multiple"
	[accept]="acceptFileTypes"
>
	<ga-tag-icon
		*ngIf="icon"
		[size]="iconSize"
		[icon]="icon"
		[type]="iconType"
	></ga-tag-icon>
	<div class="text flex-col">
		<span class="upload-info items-center"
			><span class="click-message">{{
				"file-upload-fields.click-here-message1" | transloco
			}}</span>
			{{ "file-upload-fields.click-here-message2" | transloco }}</span
		>
		<span class="items-center fileTypes"
			>{{ selectedFileTypes }}
			{{
				this.maxFileSizeMB ? "(máx. " + this.maxFileSizeMB + "mb)" : ""
			}}</span
		>
	</div>
</ngx-dropzone>
<div *ngFor="let f of files" class="justify-between fileName">
	<span><i class="fa-regular fa-paperclip-vertical"></i>{{ f.name }}</span
	><i class="fa-regular fa-xmark remove" (click)="onRemove(f)"></i>
	<img *ngIf="f.type.includes('image')" [id]="f.name" src="#" [alt]="f.name" />
</div>
<div *ngFor="let f of uploadedFilesURL" class="justify-between fileName">
	<a href="{{ f }}"
		><i class="fa-regular fa-paperclip-vertical"></i> {{ getNameByURL(f) }}</a
	><i class="fa-regular fa-xmark remove" (click)="onRemoveAttached(f)"></i>
	<img [id]="f" [src]="f" [alt]="f" />
</div>
<mat-error *ngIf="showError">{{
	error || ("global.field-required" | transloco)
}}</mat-error>
