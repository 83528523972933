<label *ngIf="title">
	<span>{{ title }}</span>
	<span *ngIf="required" class="required">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<mat-select
	*ngIf="!isMultiple && !groupSelect"
	class="form-control disabled"
	[(ngModel)]="val"
	id="select-{{ id }}"
	name="select-{{ id }}"
	(ngModelChange)="onChange(val)"
	[disabled]="disabled"
	[value]="val"
	[placeholder]="placeholder"
	(blur)="onTouch()"
	[ngClass]="{ 'has-error': showError && !disabled, disabled: disabled }"
	disableOptionCentering
>
	<mat-select-trigger>
		<ng-container [ngTemplateOutlet]="contentTriggerRef"></ng-container>
	</mat-select-trigger>
	<ng-container
		*ngIf="isSearch"
		[ngTemplateOutlet]="searchItemRef"
	></ng-container>
	<mat-option *ngIf="emptyLabel">{{ emptyLabel | transloco }}</mat-option>
	<mat-option
		*ngFor="let option of isSearch ? filteredOptions : options"
		[value]="option.id"
		[disabled]="option.disabled"
		(click)="selectionChange(option)"
	>
		<ng-container
			[ngTemplateOutlet]="dropdownItemRef"
			[ngTemplateOutletContext]="{ option: option }"
		>
		</ng-container>
	</mat-option>
</mat-select>
<mat-select
	*ngIf="isMultiple && !groupSelect"
	class="form-control"
	id="multi-{{ id }}"
	(input)="onChange(val)"
	[disabled]="disabled"
	[value]="val"
	[compareWith]="compareFn"
	[placeholder]="placeholder"
	(blur)="onTouch()"
	[ngClass]="{ 'has-error': showError && !disabled, disabled: disabled }"
	disableOptionCentering
	multiple
>
	<mat-select-trigger>
		<ng-container [ngTemplateOutlet]="contentTriggerRef"></ng-container>
	</mat-select-trigger>
	<ng-container
		*ngIf="isSearch"
		[ngTemplateOutlet]="searchItemRef"
	></ng-container>
	<ng-container [ngTemplateOutlet]="selectAllRef"></ng-container>
	<mat-option
		*ngFor="let option of isSearch ? filteredOptions : options"
		[value]="option.id"
		[disabled]="option.disabled"
		(click)="update(option.id); selectionChange(option)"
	>
		<ng-container
			[ngTemplateOutlet]="dropdownItemRef"
			[ngTemplateOutletContext]="{ option: option }"
		>
		</ng-container>
	</mat-option>
</mat-select>

<mat-select
	*ngIf="!isMultiple && groupSelect"
	class="form-control disabled"
	[(ngModel)]="val"
	id="group-{{ id }}"
	name="group-{{ id }}"
	(ngModelChange)="onChange(val)"
	[disabled]="disabled"
	[value]="val"
	[placeholder]="placeholder"
	(blur)="onTouch()"
	[ngClass]="{ 'has-error': showError && !disabled, disabled: disabled }"
	disableOptionCentering
>
	<mat-select-trigger>
		<ng-container [ngTemplateOutlet]="contentTriggerRef"></ng-container>
	</mat-select-trigger>
	<ng-container
		*ngIf="isSearch"
		[ngTemplateOutlet]="searchItemRef"
	></ng-container>
	<mat-option *ngIf="emptyLabel" class="option-label">{{
		emptyLabel | transloco
	}}</mat-option>
	<mat-optgroup
		*ngFor="let group of isSearch ? filteredOptions : groups"
		[label]="group.label"
		[disabled]="group.disabled"
	>
		<mat-option
			*ngFor="let option of group.options"
			[value]="option.id"
			[disabled]="option.disabled"
		>
			<ng-container
				[ngTemplateOutlet]="dropdownItemRef"
				[ngTemplateOutletContext]="{ option: option }"
			>
			</ng-container>
		</mat-option>
	</mat-optgroup>
</mat-select>
<mat-select
	*ngIf="isMultiple && groupSelect"
	class="form-control"
	id="multi-{{ id }}"
	(input)="onChange(val)"
	[disabled]="disabled"
	[value]="val"
	[compareWith]="compareFn"
	[placeholder]="placeholder"
	(blur)="onTouch()"
	[ngClass]="{ 'has-error': showError && !disabled, disabled: disabled }"
	disableOptionCentering
	multiple
>
	<mat-select-trigger>
		<ng-container [ngTemplateOutlet]="contentTriggerRef"></ng-container>
	</mat-select-trigger>
	<ng-container
		*ngIf="isSearch"
		[ngTemplateOutlet]="searchItemRef"
	></ng-container>
	<ng-container [ngTemplateOutlet]="selectAllRef"></ng-container>
	<mat-optgroup
		*ngFor="let group of isSearch ? filteredOptions : groups"
		[label]="group.label"
		[disabled]="group.disabled"
	>
		<mat-option
			*ngFor="let option of group.options"
			[value]="option.id"
			(click)="update(option.id); selectionChange(option)"
			[disabled]="option.disabled"
		>
			<ng-container
				[ngTemplateOutlet]="dropdownItemRef"
				[ngTemplateOutletContext]="{ option: option }"
			>
			</ng-container>
		</mat-option>
	</mat-optgroup>
</mat-select>

<mat-error *ngIf="showError && !disabled">{{
	error || ("global.field-required" | transloco)
}}</mat-error>

<ng-template #contentTriggerRef>
	<span *ngIf="!isMultiple || (!isTrigger && isMultiple)">{{
		selectedValueLabel
	}}</span>
	<ng-content *ngIf="isTrigger && isMultiple" select="p"></ng-content>
</ng-template>

<ng-template #searchItemRef>
	<section class="search-section">
		<i class="fa-regular fa-magnifying-glass search-section__icon"></i>
		<ga-input
			class="search-section__input"
			[placeholder]="'data-table.search' | transloco"
			[formControl]="searchInput"
		></ga-input>
	</section>
</ng-template>

<ng-template #selectAllRef>
	<mat-checkbox
		class="checkbox-all"
		[checked]="allComplete"
		[indeterminate]="someComplete()"
		(change)="setAll($event.checked)"
	>
		{{ selectAllText | transloco }}
	</mat-checkbox>
</ng-template>

<ng-template #dropdownItemRef let-option="option">
	<section class="dropdown-item">
		<i *ngIf="option.icon" class="fa-regular" [ngClass]="option.icon"></i>
		<div class="text-group">
			<ng-container *ngIf="!haveTranslation">
				<span [ngClass]="{ 'option-label': option.subtext }">{{
					option.label
				}}</span>
			</ng-container>
			<ng-container *ngIf="haveTranslation">
				<span [ngClass]="{ 'option-label': option.subtext }">{{
					option.label | transloco
				}}</span>
			</ng-container>
			<small *ngIf="option.subtext" class="text-group__sub">{{
				option.subtext
			}}</small>
		</div>
	</section>
</ng-template>
