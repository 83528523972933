<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar navbar-default">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">{{
						"main.menu.contracts-and-tariffs" | transloco
					}}</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()">{{
						"contract.instance.crumb.proinfa" | transloco
					}}</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span class="navbar-content__breadcrumb-page">{{
						title | transloco
					}}</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Title description of page ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{ title | transloco }}</span>
				<span class="description__texts__subtitle">{{
					subtitle | transloco
				}}</span>
			</div>
		</div>

		<!-- ------------------------- Form Module ------------------------- -->
		<form
			id="meterForm"
			[formGroup]="proinfaForm"
			class="flex-col"
			(ngSubmit)="onSubmit()"
			focusInvalidInput
		>
			<!-- Basic Data -->
			<div id="basicData" class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "global.basic-info" | transloco }}
				</h3>
				<div class="row p-uns col-lg-12">
					<!-- Organização -->
					<div class="form-group col-lg-4 col-sm-6">
						<autocomplete-orgs
							[idSelected]="organization"
							(orgEmitter)="orgSelected($event)"
							[isEnabled]="!type"
							[isRequired]="true"
						></autocomplete-orgs>
					</div>

					<!-- Nome -->
					<div class="form-group col-lg-4 col-sm-6">
						<ga-input
							[title]="'proinfa-list.label.name' | transloco"
							[placeholder]="'proinfa-list.placeholder.name' | transloco"
							[disabled]="proinfaForm.controls['name'].disabled"
							[required]="true"
							[showError]="
								proinfaForm.controls['name'].invalid &&
								proinfaForm.controls['name'].touched
							"
							formControlName="name"
							name="name"
						></ga-input>
					</div>
				</div>
				<div class="row p-uns col-lg-12" *ngIf="type !== 'edit'">
					<hr class="separator" />
				</div>
				<!-- First Cycle -->
				<div class="settings col-lg-12 p-uns" *ngIf="type !== 'edit'">
					<h3 class="settings__title col-lg-12 p-uns">
						{{
							!type
								? ("contract.form.first-cycle-info" | transloco)
								: ("contract.form.cycle-info" | transloco)
						}}
					</h3>
					<div class="form-group col-lg-3 col-sm-6">
						<ga-date-range-picker
							[title]="'contract.form.contract-validity' | transloco"
							[placeholder]="'00/00/0000 - 00/00/0000'"
							[showTime]="false"
							[showOptions]="false"
							[initialPeriod]="'custom'"
							[required]="true"
							[startWithCurrentDate]="false"
							[formControl]="dateRange"
							[disabled]="dateRange.disabled"
							[formattedDate]="formattedDate"
							[showError]="dateRange.invalid && dateRange.touched"
							(ngModelChange)="changeDate()"
						>
						</ga-date-range-picker>
					</div>
				</div>
				<div class="row p-uns col-lg-12" *ngIf="type !== 'edit'">
					<hr class="separator" />
				</div>
				<!-- Energy Volume -->
				<div
					class="settings col-lg-12 p-uns"
					formGroupName="fields"
					*ngIf="type !== 'edit'"
				>
					<h3 class="settings__title col-lg-12 p-uns">
						{{ "proinfa-list.energy-volume" | transloco }}
					</h3>
					<div
						*ngFor="let month of months; let i = index"
						class="form-group col-lg-3 col-sm-4 col-xs-12"
					>
						<ga-input
							[title]="'contract.instance.months.' + month | transloco"
							[placeholder]="'proinfa-list.value' | transloco"
							[rAddon]="'contract.instance.units.mwh' | transloco"
							[type]="'number'"
							[showError]="
								proinfaForm.get('fields.proinfa-' + (i + 1))?.invalid &&
								proinfaForm.get('fields.proinfa-' + (i + 1))?.touched
							"
							[disabled]="
								proinfaForm.get('fields.proinfa-' + (i + 1))?.disabled || false
							"
							[required]="true"
							formControlName="proinfa-{{ i + 1 }}"
						></ga-input>
					</div>
				</div>

				<!-- ------------------------- button group ------------------------- -->

				<div class="button-group flex-row">
					<div class="row p-uns col-lg-12">
						<hr class="separator" />
					</div>
					<div class="btn-toolbar" role="toolbar">
						<div class="btn-group" role="group">
							<button class="btn btn-default" (click)="backTo()">
								{{ "global.cancel" | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button
								class="btn btn-primary btn-color-ga"
								[disabled]="!proinfaForm.valid || isSaving"
								type="submit"
							>
								<span>
									{{ "global.save" | transloco }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
