<label *ngIf="title">
	<span>{{ title }}</span>
	<span *ngIf="required" class="required">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="tooltipPosition"
	></ga-helper>
</label>
<div
	class="group-section"
	[ngClass]="{
		'ga-input-error': showError && !rAddon,
		'field-disabled': disabled
	}"
>
	<span *ngIf="lAddon" class="input-group-addon">
		<i class="laddon-icon fa-regular" [ngClass]="lAddon"></i>
	</span>
	<span *ngIf="lText" class="input-group-addon">{{ lText }}</span>

	<input
		class="form-control"
		[(ngModel)]="val"
		id="input-{{ id }}"
		name="input-{{ id }}"
		(input)="onChange(inputType == 'number' ? +val : val)"
		[disabled]="disabled"
		[value]="val"
		[placeholder]="placeholder"
		(blur)="onTouch()"
		(keydown)="tryEmitEnter($event)"
		[type]="inputType"
		[min]="min"
		[max]="max"
		[attr.maxlength]="maxLength"
		[attr.minlength]="minLength"
		[step]="step"
		[ngClass]="{
			'has-error': showError,
			typeNumberError: inputType === 'number' && showError,
			hasRAddon: rAddon,
			hasLAddon: lAddon || lText
		}"
		[mask]="mask"
		[dropSpecialCharacters]="dropSpecialCharacters"
	/>
	<ng-container *ngIf="type === 'password'">
		<ng-container *ngIf="hidePassword; else showIcon">
			<i class="fa-solid fa-eye show" (click)="changePasswordVisibility()"></i>
		</ng-container>
		<ng-template #showIcon>
			<i
				class="fa-solid fa-eye-slash show"
				(click)="changePasswordVisibility()"
			></i>
		</ng-template>
	</ng-container>
	<span *ngIf="rAddon" class="input-group-addon">{{ rAddon }}</span>
</div>
<mat-error *ngIf="showError">{{
	error || ("global.field-required" | transloco)
}}</mat-error>
